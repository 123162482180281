import logo from "../../images/logo.png";

export const Logo = () => {
  return (
    <div className="navbar__logo">
      <a href="/">
        <img width={35} height={35} src={logo} alt="Russ Henson Logo" className="navbar__logo-img" />
      </a>
    </div>
  );
};
