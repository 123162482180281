import "../../css/utils.css";
import { motion } from "framer-motion";

const section = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.2,
      delayChildren: 0.1,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
};

const title = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      y: { stiffness: 100, velocity: -100, duration: 1 },
      duration: 1,
    },
  },
  hidden: {
    opacity: 0,
    y: -25,
    transition: {
      y: { stiffness: 100 },
    },
  },
};

export const SectionTitle = (props) => {
  return (
    <motion.div
      variants={section}
      initial="hidden"
      whileInView="visible"
      // viewport={{ once: true }}
      className="color-primary roboto section-title mb-sm"
    >
      <motion.h2
        variants={title}
        className="heading-secondary section-num mr-md"
      >
        {props.number}
      </motion.h2>
      <motion.h2
        variants={title}
        className="heading-secondary color-tertiary section-name"
      >
        {props.title}
      </motion.h2>
    </motion.div>
  );
};
