import { useMediaQuery } from "react-responsive";
import "../../css/projects/projects.css";

export const Slide = (props) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });

  return (
    <a
      className="project-link"
      href={props.url}
      target="_blank"
      rel="noreferrer"
    >
      <div className="project-desc-container">
        <div
          className="project-desc"
          style={isDesktop ? { padding: "0 8rem" } : { padding: "0 4rem" }}
        >
          <h1
            className="project-title color-tertiary uppercase mb-sm"
            style={isDesktop ? { fontSize: "6rem" } : { fontSize: "5.2rem" }}
          >
            {props.title}
          </h1>
          <p className="project-description color-tertiary justify">
            {props.description}
          </p>
          <ul className="project-tools">
            {props.tools.map((item, i) => (
              <li className="project-tool color-secondary" key={i}>
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <img className="proj-img" src={props.src} alt="project screenshot" />
    </a>
  );
};
