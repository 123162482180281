import { SectionTitle } from "../title/SectionTitle";
import { Button } from "../button/Button";
import { motion } from "framer-motion";

import "../../css/contact/contact.css";

import { AiFillInstagram, AiFillGithub } from "react-icons/ai";
import { BsGlobe2 } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";

import { useInView } from "react-intersection-observer";
import { forwardRef } from "react";

const container = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
      delayChildren: 1,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
};

const item = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      y: { stiffness: 100, velocity: -100, duration: 1 },
      duration: 1,
    },
  },
  hidden: {
    opacity: 0,
    y: 25,
    transition: {
      y: { stiffness: 100 },
    },
  },
};

const links = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.3,
      delayChildren: 1.5,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
};

const link = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      y: { stiffness: 100, velocity: -100, duration: 1 },
      duration: 1,
    },
  },
  hidden: {
    opacity: 0,
    y: -10,
    transition: {
      y: { stiffness: 100 },
    },
  },
};

export const Contact = forwardRef((props, ref) => {
  const [imgRef, inView] = useInView();

  const handleClick = () => {
    const email = "russjhenson@gmail.com";
    const subject = "Regarding your website";
    const body = "Hello, I would like to get in touch with you.";

    const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.open(mailtoUrl);
  };

  return (
    <section className="panel contact-section" ref={ref} id="contact">
      <div className="contact__content-container">
        <div className="noise"></div>
        <div className="contact__content">
          <div ref={imgRef} className="contact-img-container">
            {inView && (
              <motion.div
                className={`contact-img`}
                id="contact-img"
                initial={{
                  opacity: 0,
                  clipPath: "polygon(0 0, 0 100%, 0 100%, 0 0)",
                }}
                animate={{
                  opacity: 1,
                  clipPath: "polygon(0 0, 0 100%, 100% 100%, 100% 0)",
                }}
                transition={{ duration: 1.2 }}
              ></motion.div>
            )}
          </div>
          <div className={`contact__title-container`}>
            <SectionTitle number={"04."} title={`Contact`} />
          </div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={container}
            className="contact__desc-container"
          >
            <motion.p
              variants={item}
              className="contact-desc color-tertiary mb-lg"
            >
              I am actively seeking a frontend developer job in the Philippines
              or any remote opportunities worldwide. If you're interested in
              hiring me or simply want to say hello, please feel free to reach out.
              I'll get back to you as soon as possible.
            </motion.p>
            <div className="contact__btn-container">
              <Button
                variants={item}
                className={`roboto cta-btn filled-button`}
                btnName={"Say Hello"}
                onClick={handleClick}
              />
            </div>

            <motion.ul
              initial="hidden"
              whileInView="visible"
              variants={links}
              className="contact__links"
            >
              <motion.li variants={link} className="contact-link">
                <a
                  href="https://www.instagram.com/hensonruss_/"
                  target="_blank"
                  rel={"noreferrer"}
                >
                  <AiFillInstagram size={"1.6em"} color={"#1a1a1a"} />
                </a>
              </motion.li>
              <motion.li variants={link} className="contact-link">
                <a
                  href="https://github.com/russhenson"
                  target="_blank"
                  rel={"noreferrer"}
                >
                  <AiFillGithub size={"1.6em"} color={"#1a1a1a"} />
                </a>
              </motion.li>
              <motion.li variants={link} className="contact-link">
                <a
                  href="https://www.linkedin.com/in/russ-henson/"
                  target="_blank"
                  rel={"noreferrer"}
                >
                  <FaLinkedinIn size={"1.6em"} color={"#1a1a1a"} />
                </a>
              </motion.li>
              <motion.li variants={link} className="contact-link">
                <a
                  href="https://steezymnl.com/"
                  target="_blank"
                  rel={"noreferrer"}
                >
                  <BsGlobe2 size={"1.6em"} color={"#1a1a1a"} />
                </a>
              </motion.li>
            </motion.ul>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 2.7 }}
            className="contact__copyright-container color-tertiary"
          >
            <p>Designed and built by me © twentytwentythree</p>
          </motion.div>
        </div>
      </div>
    </section>
  );
});
