import { NavbarItem } from "./NavbarItem";
import { useMediaQuery } from "react-responsive";
import { motion } from "framer-motion";

const items = [
  { number: 1, name: "About", href: "#about", item: true },
  { number: 2, name: "Skills", href: "#skills", item: true },
  { number: 3, name: "Projects", href: "#projects", item: true },
  { number: 4, name: "Contact", href: "#contact", item: true },
  { number: 5, name: "Resumé", href: "/", item: false },
];

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const navItem = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      y: { stiffness: 100, velocity: -100 },
    },
  },
  hidden: {
    opacity: 0,
    y: -10,
    transition: {
      y: { stiffness: 100 },
    },
  },
};

const sidebar = {
  open: {
    x: 0,
    opacity: 1,
    display: "flex",
    transition: {
      type: "spring",
      bounce: 0,
      duration: 1,
      x: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    x: 700,
    opacity: 0,
    transitionEnd: { display: "none" },
    transition: {
      type: "spring",
      bounce: 0,
      duration: 0.8,
      x: { stiffness: 1000 },
    },
  },
};

const nav = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.07,
      delayChildren: 0.3,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
};

export const NavbarList = (props) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 991 });

  return (
    <motion.div
      variants={isSmallScreen && sidebar}
      className={!isSmallScreen ? "navbar__nav" : "navbar__nav-small"}
    >
      <motion.ul
        initial={!isSmallScreen && "hidden"}
        animate={!isSmallScreen && "visible"}
        variants={!isSmallScreen ? nav : variants}
        className={!isSmallScreen ? "navbar__list" : "navbar__list-small"}
      >
        {items.map((item, i) => (
          <NavbarItem
            variants={!isSmallScreen && navItem}
            key={i}
            number={item.number}
            href={item.href}
            name={item.name}
            item={item.item}
            selected={props.selected}
            toggle={props.toggle}
          />
        ))}
      </motion.ul>
    </motion.div>
  );
};
