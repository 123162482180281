import { useNavigate } from "react-router-dom";
import { SectionTitle } from "../title/SectionTitle";
import { Slide } from "./Slide";
import { Button } from "../button/Button";

import "../../css/projects/projects.css";
import "../../css/projects/swiper.css";
import "../../css/utils.css";

import AllProj from "../../images/projects/all.jpg";
import { useMediaQuery } from "react-responsive";
import { item } from "./Data";
import { forwardRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Swiper, SwiperSlide } from "swiper/react";
import { TypeAnimation } from "react-type-animation";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Pagination, Mousewheel, Keyboard } from "swiper";

gsap.registerPlugin(ScrollTrigger);

const container = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.2,
      delayChildren: 1.5,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
};

const desc = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      y: { stiffness: 100, velocity: -100, duration: 1 },
      duration: 1,
    },
  },
  hidden: {
    opacity: 0,
    y: 25,
    transition: {
      y: { stiffness: 100 },
    },
  },
};

const button = {
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      x: { stiffness: 100, velocity: -100, duration: 0.8 },
      duration: 0.8,
    },
  },
  hidden: {
    opacity: 0,
    x: 300,
    transition: {
      x: { stiffness: 100 },
    },
  },
};

export const Projects = forwardRef((props, ref) => {
  const isTabletOrDesktop = useMediaQuery({ minWidth: 768 });
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isBigScreen = useMediaQuery({ minWidth: 1200 });
  const navigate = useNavigate();
  const [viewRef, inView] = useInView();

  const handleClick = () => {
    navigate("/allprojects");
  };

  return (
    <section className="panel projects-section" ref={ref} id="projects">
      <div className="projects__content-container">
        <div className="noise"></div>
        <div className="projects__content">
          <div className={`projects__title-container`}>
            <SectionTitle number={"03."} title={`Featured Projects`} />
          </div>
          <Swiper
            slidesPerView={"auto"}
            centeredSlides={true}
            spaceBetween={isDesktop ? 80 : isTabletOrDesktop ? 50 : 20}
            pagination={{
              clickable: true,
            }}
            loop={true}
            keyboard={{ enabled: true }}
            grabCursor={true}
            mousewheel={{ forceToAxis: true, releaseOnEdges: true }}
            modules={[Mousewheel, Pagination, Keyboard]}
            className="mySwiper"
          >
            <SwiperSlide
              style={
                isBigScreen
                  ? { width: "60%" }
                  : isTabletOrDesktop
                  ? { width: "70%" }
                  : { width: "85%" }
              }
            >
              <motion.div
                ref={viewRef}
                className="all-proj-overlay"
                style={
                  isDesktop
                    ? { padding: "0 5rem" }
                    : isTabletOrDesktop
                    ? { padding: "0 4.6rem" }
                    : { padding: "0 3.2rem" }
                }
                initial="hidden"
                whileInView="visible"
                variants={container}
              >
                {inView && (
                  <TypeAnimation
                    className="color-tertiary"
                    style={{
                      whiteSpace: "pre-line",
                      fontSize: isDesktop
                        ? "5rem"
                        : isTabletOrDesktop
                        ? "4.6rem"
                        : "",
                    }}
                    sequence={[
                      `Make your dreams\ninto reality.\nOne at a time.`,
                      1000,
                      `Make your dreams\ninto reality.\nOne at a time.`,
                    ]}
                    repeat={0}
                    wrapper={"h1"}
                    cursor={false}
                    speed={60}
                  />
                )}
                <motion.p variants={desc} className="color-tertiary">
                  Step into a captivating world of my creations and projects.
                  Dive into a vibrant gallery showcasing my expertise in web
                  development and digital artworks.
                </motion.p>
                <Button
                  variants={button}
                  className={`roboto cta-btn filled-button`}
                  btnName={"View All Projects"}
                  onClick={handleClick}
                />
              </motion.div>
              <img src={AllProj} alt="all projects" />
            </SwiperSlide>
            {item.map((item, i) => (
              <SwiperSlide
                key={i}
                style={isTabletOrDesktop ? { width: "70%" } : { width: "85%" }}
              >
                <Slide
                  url={item.url}
                  title={item.title}
                  description={item.description}
                  src={item.src}
                  tools={item.tools}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="project-space">&nbsp;</div>
        </div>
      </div>
    </section>
  );
});
