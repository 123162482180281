import {
  Navbar,
  Hero,
  About,
  Blob,
  Skills,
  Projects,
  Contact,
} from "../components/index";
import "../css/utils.css";
import "../css/index.css";

import { useState, useEffect, useRef } from "react";

import { gsap } from "gsap";
import { ScrollTrigger, ScrollToPlugin } from "gsap/all";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

export const Landing = () => {
  const panelsRef = useRef([]);
  const observer = ScrollTrigger.normalizeScroll(true);
  const [scrollTween, setScrollTween] = useState(null);

  const goToSection = (i) => {
    setScrollTween(
      gsap.to(window, {
        scrollTo: { y: i * window.innerHeight, autoKill: false },
        onStart: () => {
          observer.disable();
          observer.enable();
        },
        duration: 1,
        onComplete: () => setScrollTween(null),
        overwrite: true,
      })
    );
  };

  useEffect(() => {
    panelsRef.current.forEach((panel, i) => {
      ScrollTrigger.create({
        trigger: panel,
        start: "top bottom",
        end: "+=199%",
        onToggle: (self) => {
          self.isActive && !scrollTween && goToSection(i);
        },
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="landing">
      <Navbar />
      <Blob />
      <Hero ref={(el) => (panelsRef.current[0] = el)} />
      <About ref={(el) => (panelsRef.current[1] = el)} />
      <Skills ref={(el) => (panelsRef.current[2] = el)} />
      <Projects ref={(el) => (panelsRef.current[3] = el)} />
      <Contact ref={(el) => (panelsRef.current[4] = el)} />
    </div>
  );
};
