import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Button } from "../button/Button";
import { motion } from "framer-motion";

const list = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.2,
      delayChildren: 1.2,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
};

const item = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      y: { stiffness: 100, velocity: -100 },
    },
  },
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      y: { stiffness: 100 },
    },
  },

  
};

const button = {
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      x: { stiffness: 100, velocity: -100, duration: 0.8 },
      duration: 0.8,
    },
  },
  hidden: {
    opacity: 0,
    x: 300,
    transition: {
      x: { stiffness: 100 },
    },
  },
};

const additionalInfo =
  "Creating and implementing my own designs is one of the most rewarding aspects of my job as a Front-end developer.";

export const HeroContent = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isBigScreen = useMediaQuery({ minWidth: 1200 });
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/allprojects");
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={list}
      className={`hero__content ${isTablet && "hero__content-tablet"} ${
        isDesktop && "hero__content-desktop"
      }`}
    >
      <motion.p variants={item} className="roboto color-primary align-text">
        Hello, I am
      </motion.p>
      <motion.h1
        variants={item}
        className="heading-primary color-secondary mb-sm"
      >
        Russ Henson.
      </motion.h1>
      <motion.h2 variants={item} className="heading-secondary mb-sm align-text">
        I design and code amazing things.
      </motion.h2>
      <motion.p
        variants={item}
        className={`align-text mb-lg ${
          (isTablet || isDesktop || isBigScreen) && "mr-lg"
        } ${isBigScreen && "content-big-screen"}`}
      >
        {`I’ve always had a passion for the creative process, but it wasn’t until
        I went to college that I learned about the possibilities of web
        development. ${
          isTablet || isDesktop || isBigScreen ? additionalInfo : ""
        }`}
      </motion.p>
      <Button
        variants={button}
        className={`align-text roboto cta-btn filled-button ${
          isMobile ? "cta-btn-mobile" : ""
        }`}
        btnName={"View Projects"}
        onClick={handleClick}
      />
    </motion.div>
  );
};
