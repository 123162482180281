import "../../css/utils.css";
import "../../css/skills/skills.css";
import { ScrollVelocity } from "../scrollvelocity/ScrollVelocity";
import { CardItems } from "./CardItems";
import { SectionTitle } from "../title/SectionTitle";
import { useMediaQuery } from "react-responsive";
import { forwardRef } from "react";
import { motion } from "framer-motion";

const desc = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      delayChildren: 1,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
};

const cards = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.2,
      delayChildren: 1.5,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
};

const item = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      y: { stiffness: 100, velocity: -100, duration: 1 },
      duration: 1,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      y: { stiffness: 100 },
    },
  },
};

const card = {
  visible: (i) => ({
    opacity: 1,
    x: 0,
    transition: {
      x: { stiffness: 100, velocity: i % 2 === 0 ? 100 : -100, duration: 1 },
      duration: 1,
    },
  }),
  hidden: (i) => ({
    opacity: 0,
    x: i % 2 === 0 ? -100 : 100,
    transition: {
      x: { stiffness: 100 },
    },
  }),
};

export const Skills = forwardRef((props, ref) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isBigScreen = useMediaQuery({ minWidth: 1200 });

  return (
    <section className="panel skills-section" ref={ref} id="skills">
      <div className="skills__content-container">
        <div className="noise"></div>
        <ScrollVelocity />
        <div className="skills__content">
          <div
            className={`glass glass-container ${
              isMobile
                ? "mobile-glass-container"
                : isBigScreen
                ? "big-glass-container"
                : ""
            }`}
          >
            <div className={`skills__title-container`}>
              <SectionTitle
                number={"02."}
                title={`${!isMobile ? "Skills && Tools" : "Skills & Tools"}`}
              />
            </div>
            <motion.div
              className={`skills__description-container mb-md`}
              style={isMobile ? { width: "100%" } : { width: "80%" }}
              initial="hidden"
              whileInView="visible"
              variants={desc}
            >
              <motion.p
                variants={item}
                className="justify color-tertiary skills__description"
              >
                These are some of the technologies and tools that I have
                utilized to develop my personal projects, and I am continually
                expanding my knowledge base by learning new technologies and
                tools during my free time.
              </motion.p>
            </motion.div>
            <motion.div
              className={`${
                !isMobile ? "skills__list" : "skills__mobile-list"
              }`}
              initial="hidden"
              whileInView="visible"
              variants={cards}
            >
              <CardItems variants={!isMobile ? card : item} />
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
});
