import { Navbar } from "../components/index";
import { motion } from "framer-motion";

import { allItem } from "../components/projects/Data";
import { TabletOrMobile, Desktop } from "../utils/Breakpoints";
import { useMediaQuery } from "react-responsive";

import "../css/index.css";
import "../css/utils.css";
import "../css/projects/allprojects.css";

const container = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.3,
      delayChildren: 1,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
};

const twoLayoutCard = (i) => ({
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      x: { stiffness: 100, velocity: i % 2 === 0 ? 20 : -20, duration: 0.7 },
      duration: 0.7,
    },
  },
  hidden: {
    opacity: 0,
    x: i % 2 === 0 ? -20 : 20,
    transition: {
      x: { stiffness: 100 },
    },
  },
});

const layoutcard = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      y: { stiffness: 100, velocity: -100, duration: 0.7 },
      duration: 0.7,
    },
  },
  hidden: {
    opacity: 0,
    y: -20,
    transition: {
      y: { stiffness: 100 },
    },
  },
};

export const AllProjects = () => {
  const isTwoLayout = useMediaQuery({ minWidth: 992, maxWidth: 1400 });

  return (
    <div className="all-projects">
      <Navbar />
      <div className="allprojects__content-container">
        <div className="noise"></div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={container}
          className="allprojects__content"
        >
          {allItem.map((item, i) => (
            <motion.div
              key={i}
              variants={isTwoLayout ? twoLayoutCard(i) : layoutcard}
              className="allprojects__card"
            >
              <a href={item.url} target="_blank" rel="noreferrer">
                <Desktop>
                  <div className="allprojects__overlay">
                    <h1>{item.title}</h1>
                    <ul>
                      {item.tools.map((item, i) => (
                        <li key={i}>{item}</li>
                      ))}
                    </ul>
                  </div>
                </Desktop>
                <img src={item.src} alt="project" />
              </a>
              <TabletOrMobile>
                <div className="allprojects__card-info mt-sm">
                  <ul className="allproject-tools">
                    {item.tools.map((item, i) => (
                      <li className="allproject-tool color-secondary" key={i}>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </TabletOrMobile>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};
