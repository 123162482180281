import { Mobile } from "../../utils/Breakpoints";
import { motion } from "framer-motion";

export const BlobMobile = () => {
  return (
    <Mobile>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 0.3 }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
        className="blob blob-right"
      ></motion.div>
    </Mobile>
  );
};
