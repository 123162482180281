import "../../css/button/button.css";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import CV from "../../pdf/Russ_Henson_CV.pdf";

import { gsap } from "gsap";
import { ScrollTrigger, ScrollToPlugin } from "gsap/all";

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

export const NavbarItem = (props) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 991 });

  const location = useLocation();
  const isProjectsPage = location.pathname === "/allprojects";

  return (
    <motion.li
      variants={isSmallScreen ? variants : props.variants}
      whileTap={{ scale: 0.95 }}
      className={!isSmallScreen ? "navbar__item" : "navbar__item-small"}
    >
      {props.item ? (
        <a
          href={!isProjectsPage ? props.href : `/${props.href}`}
          className="navbar__item-href"
          onClick={props.toggle}
        >
          <span className="navbar__item-number">{`0${props.number}.`}</span>
          {props.name}
        </a>
      ) : (
        <a
          href={CV}
          download="russ_henson_cv"
          target="__blank"
          className="navbar__resume-btn btn"
        >
          {props.name}
        </a>
      )}
    </motion.li>
  );
};
