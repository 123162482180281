import { useMediaQuery } from "react-responsive";

// COMPONENTS
export const BigScreen = ({ children }) => {
  const isBigScreen = useMediaQuery({ minWidth: 1200 });
  return isBigScreen ? children : null;
};

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};

export const TabletOrDesktop = ({ children }) => {
  const isTabletOrDesktop = useMediaQuery({ minWidth: 768 });
  return isTabletOrDesktop ? children : null;
};

export const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};

export const TabletOrMobile = ({ children }) => {
  const isTableOrMobile = useMediaQuery({ maxWidth: 991 });
  return isTableOrMobile ? children : null;
};

export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};
