import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";

export const ProgressBar = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <motion.div
      className="bg-color-tertiary progress-bar"
      // style={{ width: props.value }}
      initial={{ width: 0 }}
      whileInView={{ width: props.value }}
      transition={{ delay: !isMobile ? 5 : 4, duration: 1 }}
    >
      <motion.p
        className="roboto color-black progress-value"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: !isMobile ? 5 : 4, duration: 1 }}
      >
        {props.value}
      </motion.p>
    </motion.div>
  );
};
