import "../../css/utils.css";
import "../../css/about/about.css";
import Vector from "../../images/vector.png";
import { useMediaQuery } from "react-responsive";
import { motion } from "framer-motion";
import { BlobMobile } from "../blob/BlobMobile";
import { SectionTitle } from "../title/SectionTitle";
import { forwardRef } from "react";

const list = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.5,
      delayChildren: 1,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
};

const item = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      y: { stiffness: 100, velocity: -100, duration: 0.5 },
      duration: 0.5,
    },
  },
  hidden: {
    opacity: 0,
    y: 40,
    transition: {
      y: { stiffness: 100 },
    },
  },
};

const image = {
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      x: { stiffness: 100, velocity: -100, duration: 2 },
      duration: 4,
    },
  },
  hidden: {
    opacity: 0,
    x: -200,
    transition: {
      x: { stiffness: 100 },
    },
  },
};

export const About = forwardRef((props, ref) => {
  const isTablet = useMediaQuery({ minWidth: 768 });

  return (
    <section className="panel about-section" ref={ref} id="about">
      <div className="about__content-container">
        <BlobMobile />
        <div className="noise"></div>
        <div
          className={`about__content ${
            !isTablet ? "content-column" : "content-row"
          }`}
        >
          <motion.div
            variants={image}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="about__image-container"
          >
            <img className="about-img" src={Vector} alt="russ vector" />
          </motion.div>
          <div className="about__description-container">
            <div
              className={`about-description ${
                isTablet && "about-description-tablet"
              }`}
            >
              <SectionTitle number={"01."} title={"About Me"} />
              <motion.div
                variants={list}
                initial="hidden"
                whileInView="visible"
                // viewport={{ once: true }}
                className="color-tertiary justify"
              >
                <motion.p variants={item} className="mb-sm">
                  Hey there, I'm Russ Henson, a front-end developer
                  with a passion for crafting captivating web experiences. But
                  my journey doesn't stop there! I've also ventured into the
                  world of graphic design and even launched my very own clothing
                  brand, Steezy Manila.
                </motion.p>
                <motion.p variants={item}>
                  I've got a Bachelor's degree in Computer Science from De La
                  Salle University. Lately, I've been diving into some awesome
                  personal projects to spruce up my resume. The cool thing is,
                  most of what I know about front-end development is
                  self-taught. I've been through bootcamps and worked on a bunch
                  of projects to sharpen my skills. It's been a fun ride, full
                  of learning and improvement.
                </motion.p>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});
