import { TabletOrDesktop } from "../../utils/Breakpoints";
import { motion } from "framer-motion";
import "../../css/utils.css";

export const Blob = () => {
  return (
    <TabletOrDesktop>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 0.3 }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
        className="blob blob-big-left"
      ></motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 0.3 }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
        className="blob blob-big-right"
      ></motion.div>
    </TabletOrDesktop>
  );
};
