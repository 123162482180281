import { ParallaxText } from "./ParallaxText";
import "../../css/skills/scrollvelocity.css";
import { Mobile } from "../../utils/Breakpoints";

export const ScrollVelocity = () => {
  return (
    <div className="scroller-container">
      <ParallaxText baseVelocity={-1}>HTML5 HTML5</ParallaxText>
      <ParallaxText baseVelocity={2}>CSS3 CSS3</ParallaxText>
      <ParallaxText baseVelocity={-1}>React React</ParallaxText>
      <ParallaxText baseVelocity={2}>Javascript</ParallaxText>
      <ParallaxText baseVelocity={-1}>Node.js</ParallaxText>
      <ParallaxText baseVelocity={2}>Sass Sass</ParallaxText>
      <Mobile>
        <ParallaxText baseVelocity={-1}>Adobe Creative Suite</ParallaxText>
        <ParallaxText baseVelocity={2}>Git</ParallaxText>
      </Mobile>
    </div>
  );
};
