import { SkillCard } from "./SkillCard";
import Html5 from "../../images/logos/html5.png";
import Bootstrap from "../../images/logos/bootstrap.png";
import Css from "../../images/logos/css.png";
import Figma from "../../images/logos/figma.png";
import Git from "../../images/logos/git.png";
import Js from "../../images/logos/js.png";
import Nodejs from "../../images/logos/nodejs.png";
import Ps from "../../images/logos/photoshop.png";
import ReactImg from "../../images/logos/react.png";
import VueImg from "../../images/logos/vue.png";
import Sass from "../../images/logos/sass.png";
import Vscode from "../../images/logos/vscode.png";
import { useMediaQuery } from "react-responsive";

const item = [
  { src: Html5, alt: "html5 logo", percent: "95%" },
  { src: Bootstrap, alt: "bootstrap logo", percent: "80%" },
  { src: Css, alt: "css logo", percent: "90%" },
  { src: Figma, alt: "figma logo", percent: "90%" },
  { src: Git, alt: "git logo", percent: "70%" },
  { src: Js, alt: "javascript logo", percent: "90%" },
  { src: Nodejs, alt: "nodejs logo", percent: "75%" },
  { src: Ps, alt: "photoshopo logo", percent: "85%" },
  { src: ReactImg, alt: "react logo", percent: "85%" },
  { src: VueImg, alt: "vue logo", percent: "70%" },
  { src: Sass, alt: "sass logo", percent: "80%" },
  { src: Vscode, alt: "vscode logo", percent: "95%" },
];

const mobileItem = [
  { src: Html5, alt: "html5 logo", percent: "95%" },
  { src: Css, alt: "css logo", percent: "90%" },
  { src: Figma, alt: "figma logo", percent: "90%" },
  { src: Js, alt: "javascript logo", percent: "90%" },
  { src: Ps, alt: "photoshopo logo", percent: "85%" },
  { src: ReactImg, alt: "react logo", percent: "85%" },
];

export const CardItems = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      {!isMobile
        ? item.map((item, i) => (
            <SkillCard
              variants={props.variants}
              index={i}
              key={i}
              src={item.src}
              alt={item.alt}
              percent={item.percent}
            />
          ))
        : mobileItem.map((item, i) => (
            <SkillCard
              variants={props.variants}
              index={i}
              key={i}
              src={item.src}
              alt={item.alt}
              percent={item.percent}
            />
          ))}
    </>
  );
};
