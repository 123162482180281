import { ProgressBar } from "./ProgressBar";
import { useMediaQuery } from "react-responsive";
import { motion } from "framer-motion";

export const SkillCard = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <motion.div
      className={!isMobile ? "skill__card" : "skill__card skill__card-mobile"}
      variants={props.variants}
      custom={props.index}
    >
      <div className="skill__card-content">
        <div className="skill__logo-container">
          <img
            className={`${!isMobile ? "skill-logo" : "skill-mobile-logo"}`}
            src={props.src}
            alt={props.alt}
          />
        </div>
        <div className="skill__progress-bar-container">
          <ProgressBar value={props.percent} />
        </div>
      </div>
    </motion.div>
  );
};
