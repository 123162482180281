import { motion } from "framer-motion";

export const Button = (props) => {
  return (
    <motion.button
      variants={props.variants}
      className={`btn ${props.className}`}
      onClick={props.onClick}
      style={props.style}
    >
      {props.btnName !== "View Projects" && props.btnName  !== "View Some" ? (
        props.btnName
      ) : (
        <span>
          {props.btnName}
          <span className="arrow"> &#x27F6;</span>
        </span>
      )}
    </motion.button>
  );
};
