import "../../css/navbar/navbar.css";
import { NavbarList } from "./NavbarList";
import { Logo } from "./Logo";
import { NavbarToggle } from "./NavbarToggle";
import { Desktop, TabletOrMobile } from "../../utils/Breakpoints";
import { motion, useCycle } from "framer-motion";
import { useEffect } from "react";

export const Navbar = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "unset";
    return () => (document.body.style.overflow = "unset");
  }, [isOpen]);

  return (
    <>
      <Desktop>
        <div className="navbar navbar__content">
          <Logo />
          <NavbarList />
        </div>
      </Desktop>
      <TabletOrMobile>
        <div className="navbar navbar__content-small">
          <Logo />
          <motion.nav initial={false} animate={isOpen ? "open" : "closed"}>
            {isOpen && (
              <motion.div
                onClick={() => toggleOpen()}
                className="nav-backdrop"
              ></motion.div>
            )}

            <NavbarList toggle={() => toggleOpen()} />
            <NavbarToggle toggle={() => toggleOpen()} />
          </motion.nav>
        </div>
      </TabletOrMobile>
    </>
  );
};
