// import { Mobile } from "../../utils/Breakpoints";
import Fg from "../../images/desert-small.png";
import Bg from "../../images/desert-bg.jpg";
import "../../css/hero/hero.css";
import "../../css/utils.css";
import { ParallaxProvider } from "react-scroll-parallax";
import { ParallaxBanner } from "react-scroll-parallax";
import { HeroContent } from "./HeroContent";
import { forwardRef } from "react";

export const Hero = forwardRef((props, ref) => {
  const background = {
    image: Bg,
    translateY: [-5, 30],
    opacity: [0.3, 0],
    scale: [1.05, 1, "easeOutCubic"],
    shouldAlwaysCompleteAnimation: true,
  };

  const content = {
    translateY: [0, 25],
    opacity: [1, 0.3],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: <HeroContent />,
  };

  const foreground = {
    translateY: [0, 10],
    scale: [1, 1.1, "easeOutCubic"],
    shouldAlwaysCompleteAnimation: true,
    children: (
      <div className="fg-container">
        <img className="fg" src={Fg} alt="Dune Desert" />
      </div>
    ),
  };

  const gradientOverlay = {
    opacity: [0, 1, "easeOutCubic"],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: <div className="gradient inset" />,
  };

  return (
    <section className="panel" ref={ref} id="hero">
      <ParallaxProvider>
        <ParallaxBanner
          layers={[background, foreground, gradientOverlay, content]}
          className="full"
        />
      </ParallaxProvider>
    </section>
  );
});
